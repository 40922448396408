import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import { window } from 'browser-monads';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CtaBox from '../components/CtaBox';

import serializers from '../utilities/serializers';
import './blog-template.scss';

import IconLinkedIn from '../icons/IconLinkedIn';
import IconFacebook from '../icons/IconFacebook';
import IconTwitter from '../icons/IconTwitter';

export default function BlogArticle({ data, location }) {
  const { title, short, author, date, thumbnail, _rawBlogContent } =
    data.sanityBlogs;
  let pageUrl = location.href;
  if (location.href === undefined) {
    pageUrl = window.location.href;
  }

  return (
    <>
      <SEO
        title={title}
        description={short}
        image={thumbnail.asset.url}
        location={location || window.location}
      />

      <Layout>
        <section className="blog-hero">
          <div className="wrapper centered">
            <div className="heading-content">
              <h1>{title}</h1>
              <p className="mono info">
                by {author || 'Ninedots'} on {date}
              </p>
              <p className="summary">{short}</p>
            </div>

            <div className="hero-img">
              <GatsbyImage
                image={thumbnail.asset.gatsbyImageData}
                alt={thumbnail.alt}
              />
            </div>
          </div>
        </section>

        <section className="blog-template">
          <div className="wrapper centered">
            <div className="template-content">
              <PortableText
                blocks={_rawBlogContent}
                serializers={serializers}
              />

              <div className="share-article">
                <div className="share">
                  <p className="mono">Share</p>
                  <div className="share-btns">
                    <a
                      href={`https://facebook.com/sharer/sharer.php?u=${pageUrl}`}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconFacebook />
                    </a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconLinkedIn />
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?url=${pageUrl}`}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconTwitter />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CtaBox />
      </Layout>
    </>
  );
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    sanityBlogs(slug: { current: { eq: $slug } }) {
      title
      short
      thumbnail {
        alt
        asset {
          gatsbyImageData
          url
        }
      }
      author
      date(formatString: "MMM D, YYYY")
      _rawBlogContent
    }
  }
`;
