import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function CtaBox() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        email
        contactIreland {
          phone
        }
        ctaBox {
          title
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const { email, contactIreland, ctaBox } = data.sanitySiteSettings;

  return (
    <>
      <div className="cta-box">
        <div className="wrapper">
          <div className="cta-content">
            <div className="content">
              <h2>{ctaBox.title}</h2>
              <Link to="/contact" className="button">
                Contact us
              </Link>

              <p className="mono connect">or feel free to connect:</p>

              <div className="links">
                <a href={`mailto:${email}`} className="link">
                  {email}
                </a>
                <span />
                <a href={`tel:${contactIreland.phone}`} className="link">
                  {contactIreland.phone}
                </a>
              </div>
            </div>

            <div className="img">
              <GatsbyImage
                image={ctaBox.icon.asset.gatsbyImageData}
                alt="Get in touch with ninedots"
                objectFit="contain"
                className="invert-color"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
